.App {
  font-family: "VT323", monospace;
  font-size: large;
  text-align: center;
}

html {
  background-color: #070745;
}

button {
  font-family: "VT323", monospace;
  font-size: large;
  color: #ffcc00;
  background-color: #454545;
  padding-top: 2px;
  padding-bottom: 2px;
}

button:hover {
  color: #fbff00;
  background-color: #353535;
}

h2 {
  font-family: "VT323", monospace;
  font-size: large;
}

.normalContainer {
  display: flex;
  flex-flow: column;
  border: 5px solid #454545;
}

.app {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.countersContainer {
  display: flex;
  flex-direction: row;
  border-top: 2px solid black;
  border-bottom: 2px solid #454545;
  background-color: black;
  justify-content: space-between;
}

.graphContainer {
  display: flex;
  flex-flow: column;
  background-color: #454545;
}

.boidContainer {
  display: flex;
  flex-flow: column;
}

.normalContainer .sdContainer .isoContainer {
  display: flex;
  flex-flow: row wrap;
}

.simulationHistoryContainer {
  display: flex;
  flex-flow: column wrap;
  padding: 1px;
}

canvas {
  border: 2px solid black;
}

span {
  font-family: "VT323", monospace;
  font-size: large;
  padding: 5px;
}

.infectedText {
  color: #ffcc00;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  font-family: "VT323", monospace;
}

.normalText {
  color: #0033ff;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  font-family: "VT323", monospace;
}

.immuneText {
  color: lightgreen;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  font-family: "VT323", monospace;
}

.deadText {
  color: darkgrey;
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  font-family: "VT323", monospace;
}

svg {
  border: 1px solid black;
  padding: 1px;
}

div.swarmSliders {
  display: flex;
  flex-flow: column wrap;
}

div.sliderContainer {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  background-color: #454545;
  color: #ffcc00;
  padding: 3px;
}

div.sliderLabel {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

div.swarmButtonsContainer {
  display: flex;
  justify-content: center;
  border: 1px solid black;
  padding: 5px;
  background-color: #454545;
}

div.simpleMenu {
  display: flex;
  justify-content: center;
  flex-flow: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
}

div.replayMenu {
  display: flex;
  justify-content: center;
  flex-flow: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
  background-color: grey;
}

#menuButton {
  font-size: xx-small;
  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 2px;
  padding-left: 2px;
}

div.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  background-color: #454545;
  color: white;
  border: 4px solid black;
  padding: 5px;
  z-index: 1;
  width: 75%;
  max-width: 600px;
  max-height: 80%;
  font-family: "Roboto", sans-serif;
}

div.about > button {
  width: 100%;
}

div.scrollable {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  overflow-y: scroll;
  height: 475px;
  border-top: 5px solid #454545;
}

div.about > h2 {
  font-family: "Roboto", sans-serif;
  font-size: large;
  height: 100%;
}

div.about > p {
  padding: 0px;
  margin: 4px;
}

div.spacer {
  padding-top: 15px;
}

a {
  color: #ffcc00;
}
